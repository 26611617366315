import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  mainRoot: {
    padding: theme.spacing(1),
    borderRadius: 10,
    transition: "background-color .3s ease",
    background: "white",
    "&:hover": {
      background: theme.palette.primary.main,
      "& p": {
        color: "white",
      },
      "& div": {
        color: "white",
      },
      "& span": {
        color: "white",
      },
    },
  },

  pic: {
    borderRadius: 10,
    minHeight: 250,
    display: "flex",
    justifyContent: "center",

    "& img": {
      width:"-webkit-fill-available",
      height: 272,
      borderRadius: 10,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        height: "unset",
      },
    },
  },
  name: {
    fontSize: 16,
    fontWeight: 600,
    padding: theme.spacing(1, 1, 0.5, 1),
  },
  role: {
    fontSize: 14,
    padding: theme.spacing(0, 1, 1, 1),
    marginBottom: theme.spacing(2),
    color: "#2a3a50",
    height: 57,
    overflow: "hidden",
  },

  seeAll: {
    fontSize: 13,
    color: theme.palette.primary.main,
    display: "flex",

    "& svg": {
      marginLeft: 8,
    },
    "& g": {
      fill: theme.palette.primary.main,
    },
  },
  footerText: {
    "& span": {
      color: "gba(255, 255, 255, 0.51)",
      fontSize: 12,
      padding: theme.spacing(0, 0, 1, 1),
    },
  },
  tag: {
    border: "solid 1px rgba(42, 58, 80, 0.3)",
    color: "rgba(42, 58, 80, 0.61)",
    padding: theme.spacing(0, 0.5),
    fontSize: 11,
    borderRadius: 5,
    margin: theme.spacing(0, 0.5),
  },
}));
