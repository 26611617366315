import React, { FunctionComponent } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useStyles from "./style";
import { BottomNavigation, Grid, useTheme,Typography } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { isRtl } from "../../../utils/convertor";
import Button from "../../../components/button";

export interface ISlide {
  image: string;
  alt: string;
  link: string;
  desc:string;
}

interface IProp {
  slides: ISlide[];
}

const Slide: FunctionComponent<IProp> = ({ slides }) => {
  const classes = useStyles();
  const theme = useTheme();

  var settings = {
    dots: useMediaQuery(theme.breakpoints.down("xs")) ? false : true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 10000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // rtl: true,
  };
  return (
    <div className={classes.root}>
      <Slider {...settings}>
        {slides &&
          slides.map((item, index) => {
            return (
              <div key={index}>
                <div className={classes.slide}>
                  
                <img className={classes.imageSlide} src={item.image} alt={item.alt} />
                  <Grid
                    className={
                      isRtl() ? classes.slideContentRTL : classes.slideContent
                    }
                  >
                    <div className={classes.Info}>
                  <br></br>
                    <a href={item.link}> 
                      <Button
                  variant="outlined"
                  color="primary"
                  className={classes.sliderBtn} >

                  Read More
                </Button>
                </a>
                    </div>

                  </Grid>
                </div>
              </div>
            );
          })}
      </Slider>
    </div>
  );
};

export default Slide;
