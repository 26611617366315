import { Container, Grid, Typography } from "@material-ui/core";
import { FunctionComponent } from "react";
import useStyles from "./style";
import { formatMessage } from "../../utils/local";
import Progress from './file/progress'
import Divider from "@material-ui/core/Divider";
interface IDescriptionType {
  title: string;
  desc: string;
  image: string;
}

const certificates: IDescriptionType[] = [
  {title: "",
  desc: "",
  image: "/images/halal-cert.jpg"
},
{title: "",
desc: "",
image: "/images/gmp-cert.jpg"
},
];

const Component: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Container>
      <title>Certificates</title>
      <meta name="description" content="our certificate" />
      <Grid container justify="center" className={classes.root}>
        <Grid item md={8} sm={11} xs={12}>
          <Typography component="h1" className={classes.title}>
            {formatMessage("certificates.page.title")}
          </Typography>
          <Typography className={classes.desc}>
          </Typography>
          <Grid
            container
            justify="center"
            spacing={3}
            className={classes.container}
          >
            {certificates.map((item, index) => (
              <Grid
                item
                md={12}
                xs={12}
                container
                direction="column"
                alignItems="center"
                key={item.title + index}
                className={classes.item}
              >
                <Typography className={classes.sertifTitle} component="h2">
                  {item.title}
                </Typography>
                <Typography>{item.desc}</Typography>
                <Grid className={classes.pic}>
                  <img src={item.image} alt="certification" />
                </Grid>
                <Divider
                  className={classes.divider}
                  variant="fullWidth"
                  component="h4"
                />
                {/* <Grid item md={12} xs={12}>
                </Grid> */}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Component;
