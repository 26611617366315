import { Component } from 'react'
import L from 'leaflet';

import { MapContainer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import {
  TileLayer, Marker, Popup
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';


import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;


export default class MapDisplay extends Component {
state = {
    lat: 36.31847276820665,
    lng:  59.55242950891388,
    zoom: 18,
}


 
render() {
    const position = [this.state.lat, this.state.lng]
    return (
    <MapContainer center={position} zoom={this.state.zoom} style={{height : '400px'}}>
        <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={position}>
          <Popup>
            Rahavand Hakhamanesh
          </Popup>
        </Marker>
    </MapContainer>
    )
}
}
