import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    background: theme.palette.primary.main,
  },
  container: {
    padding: theme.spacing(7, 0),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
      padding: theme.spacing(4, 0),
    },
  },
  content: {
    color: "",
    "& h2": {
      fontSize: 16,
      paddingBottom: theme.spacing(1),
    },
    "& h3": {
      fontSize: 29,
      fontFamily: "poppins-bold",
    },
    "& p": {
      fontSize: 15,
      padding: theme.spacing(5, 0),
    },
  },
  myBtn: {
    fontSize: 15,
    color: "",
    border: "solid 1px ",
    "& img": {
      width: 24,
      paddingLeft: 8,
      position: "relative",
      top: 2,
    },
  },
  video: {
    padding: theme.spacing(2),
    objectFit: "cover",
    background: "",
    borderRadius: 10,
    width: "fit-content",
    "&>video": {
      borderRadius: 10,
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
      padding: theme.spacing(1),
    },
  },
}));
