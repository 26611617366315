import baseAxios, {
  AxiosRequestConfig,
  AxiosResponse,
  AxiosStatic as AxiosStaticBase,
} from "axios";
import { getDefaultLocale } from "../utils/config";

const language =
  sessionStorage.getItem("language") !== null
    ? sessionStorage.getItem("language")
    : getDefaultLocale;

interface IAxiosStatic extends Omit<AxiosStaticBase, "get" | "post"> {
  get<T = any, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R>;
  post<T = any, R = AxiosResponse<T>>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<R>;
}
const Axios: IAxiosStatic = {
  ...baseAxios,
  get: (url, config, context = undefined) => {
    url = `${url}`;
    let finalClientHeaders = {};
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        "accept-language": language,
        ...finalClientHeaders,
      },
    };
    return baseAxios.get(url, { ...config, ...customConfig });
  },
  post: (url, data, config) => {
    url = `${url}`;
    const customConfig = {
      headers: {
        "Content-Type": "application/json",
        "accept-language": language,
      },
    };
    return baseAxios.post(url, data, { ...config, ...customConfig });
  },
};
export default Axios;
