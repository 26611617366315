import {Container, Grid, Typography} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import useStyles from "./style";
import { productDetail } from "../../components/product";
import { getProductDetail } from "../../api/services";
import {useParams} from "react-router-dom";
import './style.css';


import Slider, { ISlide } from "./slider/index";

interface IProp {
  slides?: ISlide[];
}

const Component: FunctionComponent<IProp> = ({ slides}) => {
  const classes = useStyles();
  const [product, setBlogs] = React.useState<productDetail>();
  const [laoding, setLoading] = React.useState<boolean>(true);
  const { id }: any = useParams();
  React.useEffect(() => {
    fetchProductData();
  }, []);

  const fetchProductData = () => {
    getProductDetail(id)
      .then((response: any) => {
        console.log(response)
        setLoading(false);
        if (response) {
          setBlogs(response);
        }
      })
      .catch((er) => {
        setLoading(false);
      });
  };


  return (
    <>
      {laoding ? (
        <div className={classes.loading}>
          <img src="/images/loading.svg" alt="loading" />
        </div>
      ) : (
        <>
          <Grid className={classes.container}>
            {product && (
              <Container className={classes.root}>
                  <div className={classes.title}>
                      <Typography className={classes.title}>
                          {product.title}
                      </Typography>
                  </div>
                <Grid container justify={"center"}>
                  <Grid item md={9} xs={11}>
                    <Grid item md={12} xs={12}>
                        <Grid md={12} xs={12}>
                            <Grid md={12} xs={12}>
                              <Slider slides={product.product} />
                            </Grid>
                          <div className={classes.title}>
                            <Typography className={classes.title}>
                              Description
                            </Typography>
                          </div>
                            <div
                                className={classes.content}
                                dangerouslySetInnerHTML={{ __html: product.description }}
                            >
                            </div>
                        </Grid>

                        <Grid md={12} xs={12}>
                            <div className={classes.title}>
                              <Typography className={classes.title}>
                                Simple Specification
                              </Typography>
                            </div>
                          <div
                              className={classes.content}
                              dangerouslySetInnerHTML={{ __html: product.simple_specification }}
                          >
                          </div>
                        </Grid>

                          <Grid md={12} xs={12}>
                            <div className={classes.title}>
                              <Typography className={classes.title}>
                                Nutrition Facts
                              </Typography>
                            </div>
                          <div
                              className={classes.content}
                              dangerouslySetInnerHTML={{ __html: product.benefits }}
                          >
                          </div>
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Component;
