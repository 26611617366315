import { makeStyles } from "@material-ui/core/styles";
import { RFC_2822 } from "moment-mini";

export default makeStyles((theme) => ({
  root: {
    background: "#ebebeb",
    paddingTop: 16,
  },
  whiteRoot: {
    paddingTop: 16,
  },

  list: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& div": {
      textAlign: "center",
      padding: 0,
      "&:hover": {
        background: "transparent",
      },
      "&>a": {
        whiteSpace: "nowrap",
        color: "#7C7C7C",
        textDecoration: "none",
        fontSize: 14,
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        padding: theme.spacing(2, 2),
      },
      "&>span:first-child": {
        padding: theme.spacing(2, 1),
      },
      "&>span": {
        width: "100%",
        height: "100%",
        color: "#7C7C7C",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
  listChild: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&>img": {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  line: {
    width: "30px !important",
    borderBottom: "solid 2px #e57878",
  },
  line2: {
    width: "30px !important",
    borderBottom: "solid 2px #e57878",
    position: "absolute",
    bottom: 10,
  },
  formControl: {
    margin: theme.spacing(1),
    "&>div": {
      borderRadius: 14,
    },
  },
  select: {
    padding: "5px 10px",
  },
  selectRoot: {
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: 14,
    border: "solid 1px white",
    fontSize: 14,
    paddingRight: "28px !important",
    "&:focus": {
      background: "rgba(255, 255, 255, 0.3)",
      borderRadius: 14,
    },
  },
  menuSelected: {
    padding: 0,
    minWidth: 150,
    "& a": {
      width: "100%",
      padding: "7px 20px",
      color: "#2a3a50",
      fontSize: 14,
    },

    "&:hover": {
      background: theme.palette.primary.main,
      color: "rgba(255,255,255,.8)",
      "&>a": {
        color: "rgba(255,255,255,.8)",
      },
    },
  },
  menuSelectedRtl: {
    padding: 0,
    minWidth: 150,
    "& a": {
      width: "100%",
      padding: "7px 20px",
      color: "#2a3a50",
      fontSize: 14,
      textAlign: "right",
    },

    "&:hover": {
      background: theme.palette.primary.main,
      color: "rgba(255,255,255,.8)",
      "&>a": {
        color: "rgba(255,255,255,.8)",
      },
    },
  },
}));
