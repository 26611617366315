import { Container, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { formatMessage } from "../../../utils/local";
import useStyles from "./style";
import Blog, { blogType } from "../../../components/blog";
import { Link } from "react-router-dom";
import Button from "../../../components/button";
import Arrow from "../../../components/icons/circle-arrow";

interface IProp {
  blogs: blogType[];
}
const Component: FunctionComponent<IProp> = ({ blogs }) => {
  const classes = useStyles();
  return (
    <div>
      <Container className={classes.root}>
        <Typography component="h3" className={classes.title}>
          {formatMessage("home.blogs.title")}
        </Typography>
        <Grid container>
          <Grid item md={5} sm={12} xs={12}>
            <Typography component="h4" className={classes.subTitle}>
              {formatMessage("ROKH MAG")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container>
          {blogs &&
            blogs.map((item, index) => {
              return (
                <Grid
                  key={item.id + index}
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  className={classes.product}
                >
                  <Blog data={item} />
                </Grid>
              );
            })}
          <Grid
            item
            md={4}
            container
            justify={"center"}
            className={classes.btnContainer}
          >
            <Link to="/blogs">
              <Button
                variant="text"
                border={false}
                classes={{ root: classes.myBtn }}
                endIcon={<Arrow />}
              >
                {formatMessage("home.blogs.see_all")}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Component;
