import React, { FunctionComponent } from "react";
import Heading from "./heading";
import useStyles from "./style";
import Features from "./features";
import Video from "./video";
import Form from "./Form";
import Blogs from "./blogs";
import { Helmet } from "react-helmet";

import {getHomeData, getSliderData} from "../../api/services";

const Component: FunctionComponent = () => {
  const [data, setData] = React.useState<any>([]);
  const [laoding, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
      getSliderData()
      .then((response: any) => {
        setLoading(false);
        if (response) {
          setData(response);
        }
      })
      .catch((er) => {
        setLoading(false);
      });
  }, []);
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Helmet>
          <title>ECHfoods International - Leading Chicken Feet and Paws Supplier from Iran</title>
          <meta name="description" content="Get premium quality chicken feet and paws directly from Iran's trusted supplier, ECHfoods International. We offer a wide range of hygienic and halal-certified products to meet your business needs." />
          <meta name="keywords" content="chicken feet supplier, chicken paws supplier, Iran chicken supplier, ECHfoods International, halal chicken products, premium quality chicken feet, hygienic chicken paws, Iranian chicken exporter, poultry products, international chicken supplier" />
        </Helmet>
        <Heading slides={data} laoding={laoding} />
      </div>
      <Features />
      {data.featuredPosts?.length > 0 && <Blogs blogs={data.featuredPosts} />}
      <Video />
      <Form />
    </>
  );
};

export default Component
