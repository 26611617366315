import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  title: {
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 700,
  },
  subTitle: {
    fontSize: 30,
    fontWeight: 800,
    fontFamily: "poppins-bold",
  },
  product: {
    padding: theme.spacing(2, 1),
  },
  myBtn: {
    background: "white",
    color: theme.palette.primary.main,
    border: "solid 1px rgba(42, 58, 80, 0.2)",
    borderRadius: 10,
    fontWeight: 500,
    margin: "16px 0px",
    boxShadow: "none",
    fontSize: 16,
    marginTop: theme.spacing(2),
    width: "100%",
    height: 428,
    "&:hover": {
      background: "#FCF5F5",
    },
    "& svg": {
      marginLeft: 8,
    },
    "& g": {
      fill: theme.palette.primary.main,
    },
  },
  mobileButton: {},
  btnContainer: {
    width: "100%",
    "&>a": {
      width: "100%",
    },
  },
}));
