import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
      padding: theme.spacing(3, 2),
    }
  },
  commentsRoot: {
    padding: theme.spacing(3, 3),
    background: "rgba(42, 58, 80, 0.03)",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(3, 2),
    },
  },

  title: {
    textAlign:"center",
    marginTop: 25,
    marginBottom: 25,
    fontSize: 60,
    fontFamily: "poppins-bold",
    color:"",
  },
  content: {
    textAlign:'left',
    width:"75%",
    fontSize: 20,
    margin:"auto"
  },
  
}));
