import * as React from "react";

interface IProp {
  color?: string;
}
const SvgComponent: React.FunctionComponent<IProp> = ({ color = "#fff" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
    >
      <g fill={color} fillRule="nonzero">
        <path
          d="M10 0c5.515 0 10 4.486 10 10s-4.485 10-10 10C4.486 20 0 15.514 0 10S4.486 0 10 0"
          opacity={0.2}
        />
        <path d="M8.558 5.78c.19 0 .383.072.529.218l3.487 3.47a.756.756 0 010 1.063l-3.487 3.472a.749.749 0 11-1.058-1.062L10.982 10 8.029 7.06a.75.75 0 01.528-1.282" />
      </g>
    </svg>
  );
};

export default SvgComponent;
