import * as React from "react";
import clsx from "clsx";
import { Button as MUIButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ButtonProps } from "@material-ui/core/Button";
import useStyles from "./style";

interface IButtonType extends ButtonProps {
  hasLoader?: boolean;
  loading?: boolean;
  border?: boolean;
  noRtl?: boolean;
}

const isRtl =
  sessionStorage.getItem("rtl") !== undefined
    ? sessionStorage.getItem("rtl") === "true"
    : process.env.REACT_APP_RTL;
const Button: React.FunctionComponent<IButtonType> = React.memo(
  ({ hasLoader, loading, border = true, noRtl, children, ...buttonProps }) => {
    const classes = useStyles();

    if (hasLoader) {
      return (
        <div
          className={clsx(
            classes.buttonWrapper,
            buttonProps.className
            // border
            //   ? buttonProps.color === "primary"
            //     ? classes.myBtn
            //     : classes.myBtn2
            //   : ""
          )}
        >
          <MUIButton
            disabled={buttonProps.disabled || loading}
            {...buttonProps}
          >
            <>
              {loading && (
                <CircularProgress
                  size={24}
                  color="secondary"
                  className={classes.buttonProgress}
                />
              )}
              {children}
            </>
          </MUIButton>
        </div>
      );
    }
    return (
      <div
        className={clsx(
          // border
          //   ? buttonProps.color === "primary"
          //     ? classes.myBtn
          //     : classes.myBtn2
          //   : "",
          isRtl && !noRtl && classes.rtl
        )}
      >
        <MUIButton {...buttonProps}>{children}</MUIButton>
      </div>
    );
  }
);

export default Button;
