import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(7, 3),
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
      padding: theme.spacing(7, 2),
    },
  },
  blogImage:{
    width: 80 /* Mozilla-based browsers will ignore this. */
   },
  title: {
    fontSize: 45,
    fontFamily: "poppins-bold",
  },
  product: {
    padding: theme.spacing(2, 1),
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
      padding: theme.spacing(1),
    },
  },
  container: {
    padding: theme.spacing(3, 0),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: "100px 0",
    "& img": {
      width: 80,
    },
  },
}));
