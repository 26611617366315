import { Container, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import useStyles from "./style";
import { formatMessage } from "../../utils/local";
import { getTypesByChildren } from "../../api/services";
import { useParams } from "react-router-dom";
import { IType } from "../../App";

interface aloeveraType {
  id: string;
  cover: {
      image: string,
      alt: string
  };
  title: string;
  description: string;
  summary: string;
  saffron?: string;
  position?: string;
  right?: boolean;
}

const Component: FunctionComponent = () => {
  const classes = useStyles();
  const { id }: any = useParams();
  const [laoding, setLoading] = React.useState<boolean>(true);
  const [types, setTypes] = React.useState<aloeveraType[]>([]);
  const [selectedType, setSelectedType] = React.useState<IType>();

  React.useEffect(() => {
    // getSelectedType();
    getTypesByChildren(id)
        .then((response: any) => {
          setLoading(false);
          if (response) {
            setTypes(response);
          }
        })
        .catch((er) => {
          setLoading(false);
        });
  }, []);

  // const getSelectedType = () => {
  //   let data = sessionStorage.getItem("types");
  //
  //   if (data) {
  //     setSelectedType(
  //         JSON.parse(data)?.find((item: IType) => {
  //           return String(item.id) === String(id);
  //         })
  //     );
  //   }
  // };

  return (
      <>
        <Container className={classes.root}>
          <Helmet>
            <meta charSet="UTF-8" />
            <meta name="description" content="Explore our wide range of high-quality chicken products at ECHfoods International. We offer chicken feet and paws sourced directly from Iran." />
            <title>Products - ECHfoods International</title>
          </Helmet>
        <div className={classes.title}>
          <Typography className={classes.main_title} component={"h1"}>
            {formatMessage("products.page.main_title")}
          </Typography>
        </div>
        </Container>

        <Container className={classes.root2}>
          <Typography className={classes.caption}>
            {selectedType && (
                <>
                  <Typography component="h2">{selectedType?.title}</Typography>
                  <div className={classes.divider}></div>
                </>
            )}
          </Typography>
        </Container>
        {laoding ? (
            <div className={classes.loading}>
              <img src="/images/loading.svg" alt="loading" />
            </div>
        ) : (
            <Grid container className={classes.container}>
                <a>

                </a>
              {types.length > 0 ? (
                  types.map((item, index) => {
                    return (
                        <Grid
                            key={item.id + index}
                            item
                            md={12}
                            sm={12}
                            xs={12}
                            className={classes.type}
                        >
                          <Container>
                            <Grid
                                item
                                md={8}
                                justify="space-between"
                                container
                                direction={index % 2 === 0 ? "row" : "row-reverse"}
                                className={classes.container2}
                            >

                                <Grid item md={4} xs={12} className={classes.pic}>
                                    <Link to={`/products/${item.id}`}>
                                        {<img src={item.cover.image} alt={item.cover.alt} />}
                                    </Link>
                                </Grid>
                              <Grid item md={7} className={classes.textGrid}>
                                <Typography className={classes.titleT}>
                                  <Link to={`/products/${item.id}`}>
                                  {item.title}
                                  </Link>
                                </Typography>
                                <Typography className={classes.desc}>
                                  {item.summary}
                                </Typography>
                              </Grid>
                              {item.saffron && item.position === "left" && (
                                  <div className={classes.left}>
                                    <img src={item.saffron} alt="" />
                                  </div>
                              )}
                            </Grid>
                            {item.saffron && item.position !== "left" && (
                                <div
                                    className={
                                      item.position === "top"
                                          ? classes.top
                                          : item.position === "middle"
                                              ? classes.middle
                                              : item.position === "rotate"
                                                  ? classes.rotate
                                                  : item.position === "left"
                                                      ? classes.left
                                                      : ""
                                    }
                                >
                                  <img src={item.saffron} alt="" />
                                </div>
                            )}
                          </Container>
                        </Grid>
                    );
                  })
              ) : (
                  <Container>
                    <Grid item md={12} container justify="center">
                      <Typography className={classes.notFound}>
                        <img src="/images/warning.svg" alt="warning" />
                        {formatMessage("products.notFound")}
                      </Typography>
                    </Grid>
                  </Container>
              )}
              <Container className={classes.downContainer}>
                <Grid item md={12} container justify="center">
                  <Grid className={classes.down1}>
                    <img src="/images/down-s-1.svg" alt="" />
                    <img src="/images/down-s-2.svg" alt="" />
                  </Grid>
                  <Grid className={classes.down2}>
                    <img src="/images/down-s-3.svg" alt="" />
                    <img src="/images/down-s-4.svg" alt="" />
                  </Grid>
                </Grid>
              </Container>
            </Grid>
        )}
      </>
  );
};

export default Component;
