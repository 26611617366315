import routes from "./routes";
import { AxiosResponse, AxiosError, filterResponse } from "./client";
import axios from "./axios";
import { CancelToken } from "axios";

const selected_lang =   sessionStorage.getItem("lang") != null
? sessionStorage.getItem("lang") as string
: "en";



export const getProductsList = async (
  id: string,
  cancelToken?: CancelToken
): Promise<unknown> => {
  return await axios
    .get(`${routes.getProducts}?typeIds=${id}`)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

interface PayloadType {
  name: string;
  email: string;
  phoneNumber: string;
}
export const postUserInfo = async (
  payload: PayloadType,
  cancelToken?: CancelToken
): Promise<unknown> => {
  return await axios
    .post(routes.postUserInfo, payload)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

export const getBlogsList = async (
  cancelToken?: CancelToken
): Promise<unknown> => {
  return await axios
    .get(routes.getBlogs)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

export const getBlogsDetail = async (
  id: string,
  cancelToken?: CancelToken
): Promise<unknown> => {
  console.log(id);
  return await axios

    .get(`${routes.getBlogDetail}/${id}/` + selected_lang)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

export const getProductDetail = async (
    id: string,
    cancelToken?: CancelToken
): Promise<unknown> => {
  console.log(id);
  return await axios

      .get(`${routes.getProductDetail}/${id}/` + selected_lang)
      .then((response: AxiosResponse): Promise<unknown> => {
        return filterResponse(response);
      })
      .catch((error: AxiosError): any => {
        console.log(error)
      });
};

export const getComments = async (
  id: string,
  cancelToken?: CancelToken
): Promise<unknown> => {
  return await axios
    .get(`${routes.getComments}/${id}/comment/`)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

interface CommentPayloadType {
  name: string;
  content: string;
}
export const AddComment = async (
  payload: CommentPayloadType,
  id: string,
  cancelToken?: CancelToken
): Promise<unknown> => {
  return await axios
    .post(`${routes.addComment}/${id}/comment/`, payload)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
        console.log('ye asheq')
        console.log(error)
      });
};

export const getTypes = async (cancelToken?: CancelToken): Promise<unknown> => {
  return await axios
    .get(routes.getTypes)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

export const getTypesByChildren = async (
  id: string,
  cancelToken?: CancelToken
): Promise<unknown> => {
  return await axios
    .get(`${routes.getTypes}`)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

export const getHomeData = async (
  cancelToken?: CancelToken
): Promise<unknown> => {
  return await axios
    .get(`${routes.getHomeData}`)
    .then((response: AxiosResponse): Promise<unknown> => {
      return filterResponse(response);
    })
    .catch((error: AxiosError): any => {
      console.log(error)
    });
};

export const getSliderData = async (
    cancelToken?: CancelToken
): Promise<unknown> => {
    return await axios
        .get(`${routes.getSliderData}`)
        .then((response: AxiosResponse): Promise<unknown> => {
            return filterResponse(response);
        })
        .catch((error: AxiosError): any => {
          console.log(error)
        });
};

