import { getDefaultLocale } from "../config";
import enFile from "./EN.json";

const locale =
  sessionStorage.getItem("language") !== null
    ? sessionStorage.getItem("language")
    : getDefaultLocale;
const AppLocale = (locale: string | null): Record<string, string> => {
  if (locale) {
    switch (locale) {
      case "en":
        return enFile;
      default:
        return enFile;
    }
  } else {
    return enFile;
  }
};
export default AppLocale;

type key = string;

export const formatMessage = (key: key) => {
  const localeMessages = AppLocale(locale);
  let message = localeMessages[key] ? localeMessages[key] : key;

  return message;
};
