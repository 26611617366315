import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import About from "../pages/about";
import Contact from "../pages/contact";
import Home from "../pages/home";
import Certificates from "../pages/certificates";
import Products from "../pages/products";
import Types from "../pages/types";
import { FunctionComponent } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Blogs from "../pages/blogs";
import BlogDetail from "../pages/blogDetail";
import ProductDetail from "../pages/productDetail";

const Routes: FunctionComponent = () => {
  let location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <TransitionGroup>
      <CSSTransition key={location.key} classNames="fade" timeout={100}>
        <Switch location={location}>
          <Route path="/about" exact>
            <About />
          </Route>
          <Route path="/contact" exact>
            <Contact />
          </Route>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/certificates" exact>
            <Certificates />
          </Route>
          <Route path="/products" exact>
            <Products />
          </Route>
          <Route path="/blogs" exact>
            <Blogs />
          </Route>
          <Route path="/blog/:id" exact>
            <BlogDetail />
          </Route>
          <Route path="/products/:id" exact>
            <ProductDetail />
          </Route>
        </Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default Routes;
