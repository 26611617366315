import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { FunctionComponent } from "react";

// Configure JSS
let isRtl = false;
if (sessionStorage.rtl !== undefined) {
  isRtl = sessionStorage.rtl === "true";
} else {
  isRtl = process.env.REACT_APP_RTL === "true";
}

const jss = isRtl
  ? create({ plugins: [...jssPreset().plugins, rtl()] })
  : create({ plugins: [...jssPreset().plugins] });

const RTL: FunctionComponent = ({ children }) => {
  return <StylesProvider jss={jss}>{children}</StylesProvider>;
};

export default RTL;
