import { formatMessage } from "../../../utils/local";
import { Container, Grid, Hidden, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import useStyles from "./style";
import Button from "../../../components/button";
import { Link } from "react-router-dom";
import Slider, { ISlide } from "../slider";

interface IProp {
  slides: ISlide[];
  laoding: boolean;
}

const Heading: FunctionComponent<IProp> = ({ slides, laoding }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Grid container justify={"space-between"}>
        <Grid item md={5} xs={12} className={classes.rightText}>
          <Typography component="h1" className={classes.heading}>
            <span className={classes.ech}>ECH </span>
            {formatMessage("heading.title.h1")}
          </Typography>
          <Typography className={classes.heading2}>
            {/* {formatMessage("heading.title2.part1")} */}
            <span> {formatMessage("heading.title2.part2")}</span>,
            <span> {formatMessage("heading.title2.part3")}</span>&
            <span> {formatMessage("heading.title2.part4")}</span>
          </Typography>
          <Hidden xsDown>
            <Typography className={classes.description}>
              {formatMessage("heading.description")}
            </Typography>
            <div>
              <a href="/#about">
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.myBtn}
                  endIcon={
                    <img
                      src="/images/heading-arrow-pink.svg"
                      alt="arrow-icon"
                    />
                  }
                >
                  {formatMessage("home.header.button")}
                </Button>
              </a>
            </div>
          </Hidden>
        </Grid>

        <Grid item md={6} xs={12} className={classes.headerPic}>
          {laoding ? (
            <div className={classes.loading}>
              <img src="/images/loading.svg" alt="loading" />
            </div>
          ) : (
            <Slider slides={slides} />
          )}
        </Grid>
        <Hidden smUp>
          <Grid item md={5} xs={12}>
            <Typography className={classes.description}>
              {formatMessage("heading.description")}
            </Typography>
            <div>
              <a href="/#about">
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.myBtn}
                  endIcon={
                    <img
                      src="/images/heading-arrow-pink.svg"
                      alt="arrow-icon"
                    />
                  }
                >
                  {formatMessage("home.header.button")}
                </Button>
              </a>
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default Heading;
