import { Container, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import useStyles from "./style";
import { formatMessage } from "../../../utils/local";
import Button from "../../../components/button";
import { Link } from "react-router-dom";

const Component: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container id="about">
        <Grid className={classes.container} container justify="space-between">
          <Grid item md={5} className={classes.content} xs={12}>
            <Typography component={"h2"}>
              {formatMessage("home.video.title")}
            </Typography>
            <Typography component={"h3"}>
              {formatMessage("home.video.subTitle1")}
            </Typography>
            <Typography component={"h3"}>
              {formatMessage("home.video.subTitle2")}
            </Typography>
            <Typography component={"p"}>
              {formatMessage("home.video.desc")}
            </Typography>
            <div>
              <Link to="/about">
                <Button
                  variant="outlined"
                  color="secondary"
                  className={classes.myBtn}
                  endIcon={
                    <img src="/images/arrow-right.svg" alt="arrow-icon" />
                  }
                >
                  {formatMessage("home.video.button")}
                </Button>
              </Link>
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            <div className={classes.video}>
              <video controls poster="/images/chicken_feet.jpg">
                <source src="/videos/ECHfoods.webm" type="video/webm" />
              </video>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Component;
