import { createContext, FunctionComponent } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./layout/routes";
import Header from "./layout/header";
import Footer from "./layout/footer";
// import "./App.css";
import React from "react";
import RTLProvider from "./layout/rtlConfig";
import { getTypes } from "./api/services";

export interface IType {
  title: string;
  id: string;
  cover: string;
}

const FaFont = React.lazy(() => import("./layout/fonts/fa"));
const EnFont = React.lazy(() => import("./layout/fonts/en"));

export const ThemeContext = createContext<any>(null);

const App: FunctionComponent = () => {
  let isRtl = false;
  if (sessionStorage.rtl !== undefined) {
    isRtl = sessionStorage.rtl === "true";
  } else {
    isRtl = process.env.REACT_APP_RTL === "true";
  }
  document.body.dir = isRtl ? "rtl" : "ltr";

  const [types, setTypes] = React.useState<IType[]>([]);

  React.useEffect(() => {
    getTypes().then((response: any) => {
      if (response) {
        console.log(response)
        sessionStorage.setItem("types", JSON.stringify(response));
        setTypes(response);
      }
    });
  }, []);

  return (
    <RTLProvider>
      <React.Suspense fallback={<></>}>
        {isRtl && <FaFont />}
        {!isRtl && <EnFont />}
      </React.Suspense>
      <Router>
        <div>
          <Header types={types} />
          <div className="content">
            <Routes />
          </div>
          <Footer types={types} />
        </div>
      </Router>
    </RTLProvider>
  );
};

export default App;
