import {
  AxiosRequestConfig as baseAxiosRequestConfig,
  AxiosResponse as baseAxiosResponse,
  AxiosError as baseAxiosError,
} from "axios";
import axios from "./axios";

export const filterResponse = (
  response: baseAxiosResponse
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    console.log('response' + response.status)
    if ([400, 401, 421, 422, 406, 429].includes(response.status)) {
      return reject(response);
    } else if (response.data && response.data.success === false) {

      return reject(response);
    }
    return resolve(response.data);
  });
};

export default axios;

export type AxiosResponse = baseAxiosResponse;
export type AxiosError = baseAxiosError;
export type AxiosRequestConfig = baseAxiosRequestConfig;
