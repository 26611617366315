import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(20),
  },
  rightText: {
    padding: theme.spacing(2, 0),
  },
  heading: {
    fontSize: 42,
    fontWeight: 800,
    paddingTop: theme.spacing(4),
    fontFamily: "'Hoefler' ,'poppins'",
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      paddingTop: theme.spacing(0),
    },
  },
  heading2: {
    fontSize: 33,
    paddingBottom: theme.spacing(6),
    "& span": {
      color: theme.palette.primary.main,
      fontWeight: 800,
      fontFamily: "'Hoefler' ,'poppins'",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
      paddingBottom: theme.spacing(0),
    },
  },
  description: {
    fontSize: 15,
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(5),
      fontSize: 14,
    },
  },
  myBtn: {
    fontSize: 15,
    "& img": {
      width: 24,
      paddingLeft: 8,
      position: "relative",
      top: 2,
    },
    [theme.breakpoints.down("xs")]: {
      color: "",
      "&:hover": {
        background: "#F0F0F0",
      },
    },
  },
  headerPic: {
    "& img": {
      
    },
  },
  ech: {
    color: theme.palette.primary.main,
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: "50px 0",
    },
    "& img": {
      width: 60,
    },
  },
}));
