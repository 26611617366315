import * as React from "react";
import { TextField as MUITextField } from "@material-ui/core";
import { TextFieldProps } from "@material-ui/core/TextField";
import useStyles from "./style";

const TextField: React.FunctionComponent<TextFieldProps> = React.memo(
  React.forwardRef((props, ref) => {
    const classes = useStyles();
    return (
      <MUITextField
        {...props}
        variant="outlined"
        error={props.error}
        label={props.label}
        inputRef={ref}
        defaultValue={props.defaultValue}
        helperText={props.helperText}
        className={classes.root}
      />
    );
  })
);

export default TextField;
