import { ParallaxProvider } from "react-scroll-parallax";
import { formatMessage } from "../../utils/local";
import { Helmet } from "react-helmet";
import useStyles from "./style";

import Chicken from "./files/Chicken";

const meta = {
  title: 'Some Meta Title',
  description: 'I am a description, and I can create multiple tags',
  canonical: 'http://example.com/path/to/page',
  meta: {
      charset: 'utf-8',
      name: {
          keywords: 'react,meta,document,html,tags'
      }
  }

}


const Component = () => {
  const classes = useStyles();

  return (

    <div className="center">
      <Helmet>
      <meta charSet="UTF-8" />
      <meta name="description" content="About ECHfoods International - Leading Chicken Feet and Paws Supplier from Iran. Learn more about our company and our commitment to providing high-quality chicken products." />
      <title>About Us - ECHfoods International</title>

      </Helmet>
      <div className="test">
      <ParallaxProvider>
        <h1 className={classes.title}><b>ECHFOODS</b></h1>
        <p  className={classes.content}>{formatMessage("about.first_paragraph")}</p>
        <br></br>
        <p className={classes.content}>{formatMessage("about.second_paragraph")}</p>
        <br></br>

        <p className={classes.content}>{formatMessage("about.third_paragraph")}</p>
        <br></br>

        <div className="one_line">
        </div>
        <p className={classes.content}>{formatMessage("about.fourth_paragraph")}</p>
          <span className="motion">
            <Chicken />
          </span>
      </ParallaxProvider>
      </div>
    </div>
  );
};

export default Component;
