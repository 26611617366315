import { Container, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { formatMessage } from "../../../utils/local";
import useStyles from "./style";

const features: any = [
  {
    icon: "/images/feature-icon.svg",
    title: formatMessage("feature1.title"),
    desc: formatMessage("feature1.desc"),
  },
  {
    icon: "/images/feature-icon-1.svg",
    title: formatMessage("feature2.title"),
    desc: formatMessage("feature2.desc"),
  },
  {
    icon: "/images/feature-icon-2.svg",
    title: formatMessage("feature3.title"),
    desc: formatMessage("feature3.desc"),
  },
  {
    icon: "/images/feature-icon-3.svg",
    title: formatMessage("feature4.title"),
    desc: formatMessage("feature4.desc"),
  },
];

const Component: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Container>
      <div className={classes.root}>
        <Grid container>
          {features.map((item: any, index: number) => {
            return (
              <Grid
                key={index}
                item
                md={3}
                sm={6}
                xs={12}
                container
                alignItems="center"
                className={classes.feature}
              >
                <Grid item md={12} xs={3}>
                  <img src={item.icon} alt=" " />
                </Grid>
                <Grid item md={12} xs={8}>
                  <Typography className={classes.title}>
                    {item.title}
                  </Typography>
                  <Typography className={classes.desc}>{item.desc}</Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

export default Component;
