import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import useStyles from "./style";

const Header: FunctionComponent = () => {
  const classes = useStyles();

  const [lang, setLang] = React.useState<string>(
    sessionStorage.getItem("lang") != null
      ? sessionStorage.getItem("lang") as string
      : "en"
  );

  const toggleStyle = (lang: string) => {
    if (lang === "en") {
      sessionStorage.setItem("lang", "en");
      window.location.reload();
    } 
  };

  const onSelectLanguage = (event: React.ChangeEvent<{ value: unknown }>) => {
    setLang(event.target.value as string);
    sessionStorage.setItem("language", event.target.value as string);
      toggleStyle(event.target.value as string);
   };
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="language-select"
        id="language-select"
        value={lang}
        onChange={onSelectLanguage}
        classes={{ select: classes.select, root: classes.selectRoot }}
      >
        <MenuItem value={"en"}>En</MenuItem>
{/* 
        <MenuItem value={"cn"}>Cn</MenuItem> */}

      </Select>
    </FormControl>
  );
};

export default Header;
