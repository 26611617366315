import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    root: {
        "&>img": {
            minHeight: 400,
            [theme.breakpoints.down("xs")]: {
                minHeight: "unset",
            },
        },
        "& .slick-dots li": {
            margin: 0,
        },
        "& .slick-dots li.slick-active button:before": {
            color: theme.palette.primary.dark,
            fontSize: 8,
        },
        "& .slick-next": {
            right: 5,
            left: "auto",
            zIndex: 2,
        },
        "& .slick-prev": {
            left: 5,
            zIndex: 2,
            transform: "rotate(180deg)",
            transformOrigin: "50% 18%",
        },
        "& .slick-next:before": {
            content: "",
            color: "transparent",
            background: "url('/images/right-arrow-gray.svg') no-repeat",
        },
        "& .slick-prev:before": {
            content: "",
            color: "transparent",
            background: "url('/images/right-arrow-gray.svg') no-repeat",
        },
    },
    slide: {
        minHeight: 420,
        background: "#rrrrrr",
        padding: theme.spacing(1, 2),
        borderRadius: 8,
        position: "relative",
        display: "inline",
        justifyContent: "flex-end",
        [theme.breakpoints.down("xs")]: {
            paddingBottom: 70,
            justifyContent: "center",
            minHeight: 190,
        },
        "&>img": {
            width: 550,
            [theme.breakpoints.down("xs")]: {
                paddingBottom: theme.spacing(2),
                width: "90%",
            },
        },
    },
    slideContent: {
        position: "absolute",
        left: 0,
        bottom: 0,
        padding: theme.spacing(2, 2, 2, 2),
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1, 2, 1, 1.5),
            borderRadius: 8,
            width: "92%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row-reverse",
        },
    },
    slideContentRTL: {
        right: 0,
        position: "absolute",
        bottom: 0,
        padding: theme.spacing(2, 2, 2, 2),
        "& p": {
            textAlign: "center",
        },
        [theme.breakpoints.down("xs")]: {
            padding: theme.spacing(1, 2, 1, 1.5),
            borderRadius: 8,
            width: "92%",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row-reverse",
        },
    },
    seeAll: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            paddingTop: theme.spacing(1),
        },
        [theme.breakpoints.down(340)]: {
            paddingTop: 2,
        },
    },
    myBtn: {
        padding: theme.spacing(0.5, 1.5),
        minWidth: 130,
        textTransform: "uppercase",
        "&>span": {
            justifyContent: "space-between",
        },
    },
    rtlBtn: {
        padding: theme.spacing(0.5, 1.5),
        minWidth: 130,
        textTransform: "uppercase",
        "&>span": {
            justifyContent: "space-between",
            flexDirection: "row-reverse",
        },
    },
}));
