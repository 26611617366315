import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    "&>img": {
      minHeight: 500,
      objectFill: "cover",
      [theme.breakpoints.down("xs")]: {
        minHeight: "unset",
      },
    },
    "& .slick-dots li": {
      margin: 0,
    },
    "& .slick-dots li.slick-active button:before": {
      color: theme.palette.primary.dark,
      fontSize: 8,
    },
    "& .slick-next": {
      right: 5,
      left: "auto",
      zIndex: 2,
    },
    "& .slick-prev": {
      left: 5,
      zIndex: 2,
      transform: "rotate(180deg)",
      transformOrigin: "50% 18%",
    },
    "& .slick-next:before": {
      content: "",
      color: "transparent",
      background: "url('/images/right-arrow-gray.svg') no-repeat",
    },
    "& .slick-prev:before": {
      content: "",
      color: "transparent",
      background: "url('/images/right-arrow-gray.svg') no-repeat",
    },
  },
  slide: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    minHeight: 450,
    maxHeight: 450,
    background: "transparent",
    padding: theme.spacing(1, 2),
    borderRadius: 8,
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 70,
      justifyContent: "center",
      minHeight: 190,
    },
    "&>img": {
      [theme.breakpoints.down("xs")]: {
        paddingBottom: theme.spacing(2),
        width: "90%",
      },
    },
  },
  slideContent: {
    position: "absolute",
    right: 0,
    bottom: 0,
    inlineSize:"-webkit-fill-available",
    padding: theme.spacing(2, 2, 2, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2, 1, 1.5),
      borderRadius: 8,
      width: "92%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
    },
  },
  slideContentRTL: {
    right: 0,
    position: "absolute",
    bottom: 0,
    padding: theme.spacing(2, 2, 2, 2),
    "& p": {
      textAlign: "right",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1, 2, 1, 1.5),
      borderRadius: 8,
      width: "92%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
    },
  },
  name: {
    fontWeight: 600,
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 16,
      textAlign: "right",
    },
    [theme.breakpoints.down(340)]: {
      fontSize: 15,
    },
  },
  weight: {
    fontSize: 16,
    color: "#8E9299",
    padding: theme.spacing(0.5, 0),
    [theme.breakpoints.down("xs")]: {
      textAlign: "right",
    },
  },
  seeAll: {
    paddingTop: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.down(340)]: {
      paddingTop: 2,
    },
  },
  myBtn: {
    padding: theme.spacing(0.5, 1.5),
    minWidth: 130,
    textTransform: "uppercase",
    "&>span": {
      justifyContent: "space-between",
    },
  },
  rtlBtn: {
    padding: theme.spacing(0.5, 1.5),
    minWidth: 130,
    textTransform: "uppercase",
    "&>span": {
      justifyContent: "space-between",
      flexDirection: "row-reverse",
    },
  },
  sliderBtn: {
    display: "relative",
    backgroundColor:"",
    fontSize: 10,
    border: "solid 1px white",
    "& img": {
      width: 15,
      paddingLeft: 2,
      position: "relative",
    },
  },
  bannerText: {

    fontSize: 25,
    color:"#C8C0B4",
    background:'#22272F',
    border: "solid 3px",
    borderRadius:"5px",
  }
  ,
  Info: {
    textAlign:"center",
    width:"100%"
  },
  imageSlide: {
    width: "500px",
    height: "fit-content"
  }
}));
