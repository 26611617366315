import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "../src/utils/theme";
import { ParallaxProvider } from 'react-scroll-parallax';

ReactDOM.render(
      <ParallaxProvider>
          <ThemeProvider theme={theme}>
              <App />
          </ThemeProvider>
      </ParallaxProvider>
,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
