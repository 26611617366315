import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(6, 3),

    [theme.breakpoints.down("sm")]: {
      fontSize: 23,
      padding: theme.spacing(4, 2, 0, 2),
    },
  },
  title: {
    "& img": {
      width: 150,
      [theme.breakpoints.down("xs")]: {
        width: 150,
      },
    },
  },
  container: {
    padding: theme.spacing(4, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0, 2, 0),
    },
  },
  caption: {
    "& h3": {
      color: "#2a3a50",
      fontSize: 30,
      fontFamily: "poppins-bold",
      background: "white",
      paddingRight: 16,
      paddingLeft: 16,

      display: "inline-block",
    },
    padding: theme.spacing(0, 0, 2, 0),
  },
  divider: {
    width: "93%",
    height: 3,
    background: "rgba(42, 58, 80, 0.07)",
    display: "flex",
    position: "absolute",
    top: "40%",
    zIndex: -1,
  },
  root2: {
    position: "relative",
  },
  type: {
    "&>div": {
      padding: theme.spacing(6, 0),
      position: "relative",
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3, 2),
      },
    },
    "&:nth-child(odd)": {
      background: "#F8F9F9",
      [theme.breakpoints.down("sm")]: {
        background: "white",
        "&>div>div": {
          padding: theme.spacing(3, 2),
          background: "#FCF5F5",
          borderRadius: 10,
        },
      },
    },
  },
  pic: {
    "& img": {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: theme.spacing(0, 0, 3, 0),
      justifyContent: "center",
      "& img": {
        width: 250,
      },
    },
    [theme.breakpoints.down("xs")]: {
      "& img": {
        width: 180,
      },
    },
  },
  titleT: {
    fontWeight: "bold",
    fontSize: 22,
    paddingBottom: theme.spacing(2),
  },
  desc: {
    fontSize: 15,
  },
  textGrid: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
  },
  top: {
    width: 70,
    position: "absolute",
    top: -25,
    right: 0,
    [theme.breakpoints.down("md")]: {
      right: 80,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  middle: {
    width: 40,
    position: "absolute",
    top: 70,
    left: 30,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rotate: {
    width: 70,
    position: "absolute",
    top: -25,
    right: 0,
    transform: "rotate(95deg)",
    [theme.breakpoints.down("md")]: {
      right: 80,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  left: {
    width: 90,
    position: "absolute",
    top: -60,
    left: 0,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  container2: {
    position: "relative",
  },
  down1: {
    paddingRight: theme.spacing(4),
    "& img:first-child": {
      position: "relative",
      top: 40,
    },
    "& img:last-child": {
      position: "relative",
      right: 40,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  down2: {
    paddingLeft: theme.spacing(6),
    "& img:first-child": {
      position: "relative",
      top: 80,
      [theme.breakpoints.down("sm")]: {
        top: 65,
      },
    },
    "& img:last-child": {
      position: "relative",
      top: 80,
      [theme.breakpoints.down("sm")]: {
        top: 65,
      },
    },
  },
  downContainer: {
    position: "relative",
    paddingTop: theme.spacing(3),
    "&>div": {
      position: "absolute",
      top: -60,
      left: 0,
    },
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: "100px 0",
    "& img": {
      width: 100,
    },
  },
  notFound: {
    padding: theme.spacing(5, 0, 15, 0),
    fontSize: 23,
    display: "flex",
    alignItems: "center",
    "& img": {
      width: 30,
      marginRight: 16,
      marginLeft: 16,
    },
  },
  main_title: {
    fontSize: 50,
    fontFamily: "poppins-bold",
    paddingBottom: theme.spacing(3),
    color: "#C7403B",
  }
}));
