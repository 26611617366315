import { Container, Grid } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import useStyles from "./style";
import Blog, { blogType } from "../../components/blog";
import { formatMessage } from "../../utils/local";
import { getBlogsList } from "../../api/services";

const Component: FunctionComponent = () => {
  const classes = useStyles();
  const [blogs, setBlogs] = React.useState<blogType[]>([]);
  const [laoding, setLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    getBlogsList().then((response: any) => {
      setLoading(false);
      if (response) {
        setBlogs(response);
      } else {
        console.log(response)
      }
    });
  }, []);

  return (
    <Container className={classes.root}>
      <div className={classes.title}>
        {formatMessage("home.blogs.sub_title")}
      </div>
      {laoding ? (
        <div className={classes.loading}>
          <img src="/images/loading.svg" alt="loading" />
        </div>
      ) : (
        <Grid container className={classes.container}>
          {blogs.map((item, index) => {
            return (
              <Grid
                key={item.id + index}
                item
                md={4}
                sm={6}
                xs={12}
                className={classes.product}
              >
                <Blog data={item} />
              </Grid>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default Component;
