import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    fontFamily: "BrushScriptMT, Brush Script MT",
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: 70,
      },
    },
  },
}));
