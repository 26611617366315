import { createMuiTheme } from "@material-ui/core/styles";
const defaultTheme = createMuiTheme();
// Create a theme instance.
const theme = createMuiTheme({
  // direction: 'rtl',
  palette: {
    primary: {
      dark: "#077C77",
      main: "#CA6A6A",
      contrastText: "#fff", //button text white instead of black
    },
    secondary: {
      dark: "#1D2838",
      main: "#2a3a50",
      contrastText: "#fff", //button text white instead of black
    },
    error: {
      main: "#077C77",
    },
    background: {
      default: "#CA6A6A",
    },
    text: {
      primary: "#2a3a50",
    },
  },
  typography: {
    fontFamily: ["poppins ", "sans-serif"].join(","),
  },
  overrides: {
    MuiButton: {
      root: {
        fontWeight: "normal",
        textTransform: "initial",
        padding: "12px 24px",
        borderRadius: 10,
      },
      outlined: {
        padding: "12px 24px",
      },
    },
    MuiTypography: {},
  },
});

export default theme;
