import React, { FunctionComponent } from "react";
import Button from "../../../components/button";
import TextField from "../../../components/textField";
import useStyles from "./style";
import { formatMessage } from "../../../utils/local";
import {
  Container,
  Grid,
  Hidden,
  InputAdornment,
  Typography,
  DialogContent,
} from "@material-ui/core";
import MapDisplay from "./map";
import { postUserInfo } from "../../../api/services";
import Dialog from "../../../components/dialog";

interface IErrorType {
  [index: string]: { status: boolean; text?: string | null };
}

const Component: FunctionComponent = () => {
  const [userName, setUserName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [phone, setPhone] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState<boolean>(false);
  const [erroralert, seterrorAlert] = React.useState<boolean>(false);

  const [open, setOpen] = React.useState(false);

  const [error, setError] = React.useState<IErrorType>({
    email: { status: true },
    name: { status: true },
    phone: { status: true },
    message: { status: true },
  });

  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChangeInputs = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    handler: React.Dispatch<string>,
    name: string
  ) => {
    handler(String(event.target.value));
    if (error[name].status === false) {
      setError((prevError) => {
        return {
          ...prevError,
          [name]: { status: true },
        };
      });
    }
  };

  const validateEmail = (email: string): boolean => {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const checkValidation = () => {
    console.log('check')
    let errorTmp: IErrorType = { ...error };
    let valid = true;
    if (!userName) {
      errorTmp["name"] = { status: false };
      valid = false;
    }
    if (!email) {
      errorTmp["email"] = { status: false };
      valid = false;
    }

    setError(errorTmp);
    return valid;
  };

  const onSubmit = () => {
    console.log('submit')
    if (alert) {
      setAlert(false);
    }
    if (checkValidation()) {
      if (!validateEmail(email)) {
        handleClickOpen();
      } else {
        setLoading(true);
        const data = {
          name: userName,
          email,
          phoneNumber: phone,
        };

        postUserInfo(data).then(
          (response: any) => {
            setLoading(false);
            setEmail("");
            setUserName("");
            setPhone("");
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
            }, 4000);
        })
        .catch(() => {
          setLoading(false);
          setEmail("");
          setUserName("");
          setPhone("");
          seterrorAlert(true);
          setTimeout(() => {
            seterrorAlert(false);
          }, 4000);
        })
        ;
      }
    }
  };

  return (
    <>
      <Container>
        <Grid id="contact" container justify="center" className={classes.root}>
          <Grid item lg={6} md={7} sm={12} xs={12}>
            <Grid container>
              <Grid item md={5} sm={12} xs={12}>
                <Typography component="h5" className={classes.title}>
                  {formatMessage("form.title1")}
                </Typography>
              </Grid>
            </Grid>
            <Typography>{formatMessage("form.desc")}</Typography>
            <Grid container className={classes.container}>
              <Grid item md={12} xs={12} className={classes.filedBox}>
                <TextField
                  error={!error.name.status}
                  value={userName}
                  type="name"
                  fullWidth
                  onChange={(e) => onChangeInputs(e, setUserName, "name")}
                  id="name-input"
                  name="name"
                  placeholder={formatMessage("form.field.fullname")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/name.svg" alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.filedBox}>
                <TextField
                  error={!error.email.status}
                  value={email}
                  type="email"
                  fullWidth
                  onChange={(e) => onChangeInputs(e, setEmail, "email")}
                  id="email-input"
                  name="email"
                  placeholder={formatMessage("form.field.email")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/email.svg" alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.filedBox}>
                <TextField
                  error={false}
                  value={phone}
                  type="email"
                  fullWidth
                  onChange={(e) => onChangeInputs(e, setPhone, "phone")}
                  id="phone-input"
                  name="phone"
                  placeholder={formatMessage("form.field.number")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src="/images/phone.svg" alt="" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12} className={classes.filedBoxButton}>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<img src="/images/send.svg" alt="" />}
                  hasLoader
                  loading={loading}
                  onClick={onSubmit}
                  border={false}
                >
                  <span className={classes.btnText}>
                    {formatMessage("form.button.submit")}
                  </span>
                </Button>
                {alert && (
                  <div className={classes.alert}>
                    {formatMessage("home.userInfo.form.success")}
                  </div>
                )}
                {erroralert && (
                  <div className={classes.erroralert}>
                    {formatMessage("home.userInfo.form.error")}
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="center" className={classes.root2}>
          <Grid item lg={6} md={7} sm={12} xs={12}>
            <Grid container justify="space-between">
              <Grid item md={4} sm={12} xs={12} className={classes.infoItem}>
                <div>
                  <Hidden smDown>
                    <img src="/images/phone-icon.svg" alt="" />
                  </Hidden>
                  <Hidden mdUp>
                    <img src="/images/phone-mobile.svg" alt="" />
                  </Hidden>
                </div>
                <div className={classes.text}>
                  <Typography>
                    {formatMessage("home.information.mobile")}
                  </Typography>
                  <span><a href="tel:+989159156342">{process.env.REACT_APP_MOBILE}</a></span>
                </div>
              </Grid>
              <Grid item md={4} sm={12} xs={12} className={classes.infoItem}>
                <div>
                  <Hidden smDown>
                    <img src="/images/email-icon.svg" alt="" />
                  </Hidden>

                  <Hidden mdUp>
                    <img src="/images/email-mobile.svg" alt="" />
                  </Hidden>
                </div>
                <div className={classes.text}>
                  <Typography>
                    {formatMessage("home.information.email")}
                  </Typography>
                  <span>{process.env.REACT_APP_Email}</span>
                </div>
              </Grid>
              <Grid item md={3} sm={12} xs={12} className={classes.infoItem}>
                <div>
                  <Hidden smDown>
                    <img src="/images/fax.svg" alt="" />
                  </Hidden>

                  <Hidden mdUp>
                    <img src="/images/fax-mobile.svg" alt="" />
                  </Hidden>
                </div>
                <div className={classes.text}>
                  <Typography>
                    {formatMessage("home.information.fax")}
                  </Typography>
                  <span><a href="https://wa.me/+989159156342" target="_blank">{process.env.REACT_APP_FAX}</a></span>
                </div>
              </Grid>
            </Grid>
            <Grid item md={12} xs={12} className={classes.map}>
              <div>
                <MapDisplay />
              </div>
            </Grid>
            <Grid item container md={12} xs={12}>
              <Grid item md={10} sm={12} xs={12} className={classes.infoItem2}>
                <div>
                  <Hidden smDown>
                    <img src="/images/address.svg" alt="" />
                  </Hidden>

                  <Hidden mdUp>
                    <img src="/images/address-mobile.svg" alt="" />
                  </Hidden>
                </div>
                <div className={classes.text2}>
                  <Typography>
                    {formatMessage("home.information.address")}
                  </Typography>
                  <span>{process.env.REACT_APP_ADDRESS}</span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogContent className={classes.dialog}>
          <img src="/images/warning.svg" alt="" />
          <Typography>
            {formatMessage("home.userInfo.form.error.dialog.email")}
          </Typography>
          <div>
            <Button variant="contained" color="secondary" onClick={handleClose}>
              {formatMessage("home.userInfo.form.error.dialog.button.close")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Component;
