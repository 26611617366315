import { makeStyles } from '@material-ui/core/styles';
export default makeStyles((theme) => ({
  closeIcon: {
    zIndex: 4000,
    position: 'fixed',
    top: '4px',
    left: '4px',
    backgroundColor: '#eeeeee59',
  },
}));
