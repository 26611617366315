import {
  Container,
  Grid,
  Hidden,
  List,
  ListItem,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";
import Logo from "../logo";
import useStyles from "./style";
import { Link, useLocation } from "react-router-dom";
import { formatMessage } from "../../utils/local";
import Drawer from "../../components/drawer";
import LanguageSelect from "./languageSelect";
import { IType } from "../../App";

interface IProp {
  types: IType[];
}
const Header: FunctionComponent<IProp> = ({ types }) => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <div
      className={location.pathname === "/" ? classes.root : classes.whiteRoot}
    >
      <Container>
        <Grid container justify="space-between">
          <Grid item lg={1} md={1} xs={2}>
            <Logo />
          </Grid>
          <Hidden smDown>
            <Grid item lg={7} md={8} container>
              <List
                component="nav"
                className={classes.list}
                aria-label="contacts"
              >
                <ListItem button>
                  <Link to="/">
                    <span>{formatMessage("menuItem.home")}</span>
                    <span
                      className={location.pathname === "/" ? classes.line : ""}
                    ></span>
                  </Link>
                </ListItem>
                <ListItem button>
                  <Link to="/products">
                    <span>{formatMessage("menuItem.products")}</span>
                    <span
                        className={
                          location.pathname === "/products" ? classes.line : ""
                        }
                    ></span>
                  </Link>
                </ListItem>
                <ListItem button>
                  <Link to="/certificates">
                    <span> {formatMessage("menuItem.certificates")}</span>
                    <span
                      className={
                        location.pathname === "/certificates"
                          ? classes.line
                          : ""
                      }
                    ></span>
                  </Link>
                </ListItem>
                <ListItem button>
                  <Link to="/about">
                    <span>{formatMessage("menuItem.about")}</span>
                    <span
                      className={
                        location.pathname === "/about" ? classes.line : ""
                      }
                    ></span>
                  </Link>
                </ListItem>
                <ListItem button>
                  <a href="/#contact">
                    <span> {formatMessage("menuItem.contact")}</span>
                    <span
                      className={
                        location.pathname === "/contact" ? classes.line : ""
                      }
                    ></span>
                  </a>
                </ListItem>
                <ListItem button>
                  <Link to="/blogs">
                    <span> {formatMessage("menuItem.blog")}</span>
                    <span
                      className={
                        location.pathname === "/blogs" ? classes.line : ""
                      }
                    ></span>
                  </Link>
                </ListItem>
              </List>
            </Grid>
          </Hidden>
          <Hidden smDown>
            <Grid item lg={1} md={1} container alignItems="center">
              <LanguageSelect />
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item md={1} sm={1} xs={2}>
              <Drawer types={types} />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </div>
  );
};

export default Header;

