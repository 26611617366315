import {
  Container,
  Grid,
  Typography,
  List,
  ListItem,
  Hidden,
} from "@material-ui/core";
import { FunctionComponent } from "react";
import useStyles from "./style";
import { formatMessage } from "../../utils/local";
import { Link } from "react-router-dom";
import { IType } from "../../App";
import Slider from "./slider";

const socials = [
  // {
  //   icon: "/images/footer/twitter.svg",
  //   link: process.env.REACT_APP_TWITTER,
  // },
  // {
  //   icon: "/images/footer/instagram.svg",
  //   link: process.env.REACT_APP_INSTAGRAM,
  // },
  {
    icon: "/images/footer/linkedin.svg",
    link: process.env.REACT_APP_LINKEDIN,
  },
];

const certificateImages = [
  // "/images/footer/footer-certificate.jpg",
  // "/images/footer/footer-certificate.jpg",
  // "/images/footer/footer-certificate.jpg",
];
interface IProp {
  types: IType[];
}
const Component: FunctionComponent<IProp> = ({ types }) => {
  const classes = useStyles();

  const renderSocials = () => {
    return (
      <Grid item md={2} sm={6} xs={12}>
        <Typography>{formatMessage("footer.title4")}</Typography>
        <Grid container className={classes.socials}>
          {socials.map((item) => (
            <Grid key={item.link}>
              <a href={item.link} target="blank">{<img src={item.icon} alt="icon" />}</a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid className={classes.container} container justify={"center"}>
          <Grid item md={11} sm={12} xs={12}>
            <Grid container className={classes.container2}>
              <Hidden smUp>{renderSocials()}</Hidden>
              <Grid item md={3} sm={6} xs={12}>
                <Typography>{formatMessage("footer.title2")}</Typography>
                <List
                  component="nav"
                  className={classes.list}
                  aria-label="contacts"
                >
                  <ListItem button>
                    <Link to="/">{formatMessage("footer.title2.option1")}</Link>
                  </ListItem>
                  <ListItem button>
                    <Link to="/products/7">
                      {formatMessage("footer.title2.option3")}
                    </Link>
                  </ListItem>
                  <ListItem button>
                    <Link to="/certificates">
                      {formatMessage("footer.title2.option4")}
                    </Link>
                  </ListItem>
                  <ListItem button>
                    <Link to="/blogs">
                      {formatMessage("footer.title2.option7")}
                    </Link>
                  </ListItem>
                </List>
              </Grid>

              <Grid item md={3} sm={6} xs={12}>
                <Typography>{formatMessage("footer.title5")}</Typography>
                <List
                  component="nav"
                  className={classes.list}
                  aria-label="contacts"
                >
                  {types.map((item) => {
                    return (
                      <ListItem button key={item.id + "m"}>
                        <Link to={`/products/${item.id}`}>{item.title}</Link>
                      </ListItem>
                    );
                  })}
                </List>
              </Grid>

              <Grid item md={4} sm={6} xs={12}>
                <Typography>{formatMessage("footer.title3")}</Typography>
                <Grid container className={classes.slideContainer}>
                  <Grid item md={7} xs={8} className={classes.certif}>
                    {/* <Link to="/certificates">
                    <img src="/images/certificates/certificate-1.jpg" alt="" />
                  </Link> */}
                    {/* <Slider slides={certificateImages} /> */}
                  </Grid>
                </Grid>
              </Grid>
              <Hidden xsDown>{renderSocials()}</Hidden>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <div className={classes.copyright}>
        {formatMessage("footer.copyright")}
      </div>
    </div>
  );
};

export default Component;
