import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 0),
    "&>div": {
      borderBottom: "solid 1px #B7B7B7",
      marginBottom: theme.spacing(0),
    },
  },
  root2: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontFamily: "poppins-bold",
    fontSize: 36,
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
  },
  filedBox: {
    padding: theme.spacing(1, 0),
    "& label": {
      padding: theme.spacing(1, 0, 2, 0),
      fontWeight: 600,
    },
  },
  filedBoxButton: {
    padding: theme.spacing(1, 0),
    display: "flex",
    alignItems: "center",
  },
  btnText: {
    padding: theme.spacing(0, 1),
  },
  container: {
    padding: theme.spacing(2, 0),
  },
  infoItem: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: theme.spacing(1, 2),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      alignItems: "center",
      borderRadius: 10,
      background: "#f1f4fd",
      "&:first-child": {
        background: "#f1f8f0",
      },
      "&:last-child": {
        background: "#ebf8fa",
      },
    },
    "& p": {
      fontWeight: "bold",
      padding: theme.spacing(0.5, 0),
      fontSize: 15,
    },
    "& span": {
      color: "#7C7C7C",
      fontSize: 14,
    },
  },
  infoItem2: {
    display: "flex",
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    alignItems: "center",
    borderRadius: 10,
    [theme.breakpoints.down("sm")]: {
      background: "#fdf5f1",
    },
    "& p": {
      fontWeight: "bold",
      padding: theme.spacing(0.5, 0),
      fontSize: 15,
    },
    "& span": {
      color: "#7C7C7C",
      fontSize: 14,
    },
  },
  text2: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
  },
  text: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(3),
    },
  },
  map: {
    padding: theme.spacing(2, 1, 2, 0),
    "&>div": {
      position: "relative",
      height: 400,
    },
  },
  alert: {
    background: "rgb(237, 247, 237)",
    color: "rgb(30, 70, 32)",
    padding: theme.spacing(1, 2),
    fontSize: 14,
    borderRadius: 4,
    marginLeft: 16,
  },
  dialog: {
    "& img": {
      width: 100,
    },
    "& p": {
      padding: theme.spacing(3, 0),
      textAlign: "center",
    },
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  message: {
    color: "#2a3a50",
    fontWeight: "bold",
  },
  erroralert: {
    background: "#FFEBEE",
    color: "#B71C1C",
    padding: theme.spacing(1, 2),
    fontSize: 14,
    borderRadius: 4,
    marginLeft: 16,
  },
}));
