import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    background: "#2b2e31",
  },
  container: {
    padding: theme.spacing(4, 0, 2, 0),

    "& p": {
      color: "#EBEBEB",
      fontSize: 14,
    },
  },
  copyright: {
    fontSize: 13,
    textAlign: "center",
    background: "#1d2025",
    color: "#EBEBEB",
    padding: theme.spacing(1, 0),
  },
  list: {
    "& div": {
      padding: "4px 0",
      "&>a": {
        color: "#7C7C7C",
        textDecoration: "none",
        fontSize: 14,
        display: "flex",
        width: "100%",
        height: "100%",
        alignItems: "center",
        [theme.breakpoints.down("xs")]: {
          display: "block",
          textAlign: "center",
        },
      },
    },
  },
  socials: {
    padding: theme.spacing(1, 0),
    "& div": {
      padding: theme.spacing(0, 0.5),
    },
  },
  certif: {
    padding: theme.spacing(1, 0),
    "& a": {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        justifyContent: "center",
      },
    },
    "& img": {
      // width: "80%",
      padding: theme.spacing(1, 0),
      // [theme.breakpoints.down("sm")]: {
      //   width: 200,
      //   height: 290,
      // },
    },
  },
  container2: {
    "&>div": {
      padding: theme.spacing(2, 0),
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
      "&>div": {
        padding: theme.spacing(2, 0),
      },
      "&>div>p": {
        textAlign: "center",
      },
    },
  },
  slideContainer: {
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
    },
  },
}));
