const baseUrl = "https://api.echfoods.com";

const selected_lang =   sessionStorage.getItem("lang") != null
? sessionStorage.getItem("lang") as string
: "en";


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: baseUrl,
  getProducts: `${baseUrl}/product/` + selected_lang,
  postUserInfo: `${baseUrl}/home/newlettersubscriber/`,
  getBlogs: `${baseUrl}/blog/posts/` + selected_lang,
  getBlogDetail: `${baseUrl}/blog/post`,
  getProductDetail: `${baseUrl}/product`,
  getComments: `${baseUrl}/blog/post`,
  addComment: `${baseUrl}/blog/post`,
  getTypes: `${baseUrl}/product/list/` + selected_lang,
  getHomeData: `${baseUrl}/home`,
  getSliderData: `${baseUrl}/home/slider/` + selected_lang,
};
