import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {},
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  line: {
    width: 30,
    borderBottom: "solid 2px #e57878",
    display: "block",
    position: "relative",
    left: 0,
  },
  lang: {
    padding: theme.spacing(2, 0),
  },
  item: {
    "& a": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
    },
  },
  item2: {
    "& a": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  nestedChild: {
    paddingLeft: theme.spacing(4),
  },
}));
