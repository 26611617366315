import React from "react";
import { Dialog as MUIDialog } from "@material-ui/core";
import { DialogProps } from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import useStyles from "./style";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Dialog: React.FunctionComponent<
  DialogProps & {
    isClosable?: boolean;
  }
> = ({ children, isClosable = true, onClose, ...props }) => {
  const classes = useStyles();

  return (
    <>
      <MUIDialog
        TransitionComponent={Transition}
        onClose={isClosable ? onClose : undefined}
        {...props}
      >
        {children}
      </MUIDialog>
    </>
  );
};

export default React.memo(Dialog);
