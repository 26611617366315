import { Container, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import useStyles from "./style";
import { blogType } from "../../components/blog";
import { formatMessage } from "../../utils/local";
import { getBlogsDetail, getComments, AddComment } from "../../api/services";
import { useParams } from "react-router-dom";
import TextField from "../../components/textField";
import Button from "../../components/button";
import moment from "moment-mini";

interface IComments {
  _id: string;
  coverImage: {
    downloadUrl: string;
  };
  name: string;
  createdAt: string;
  content: string;
  slug: string;
  reply?: {
    text: string;
  };
}

interface IErrorType {
  [index: string]: { status: boolean; text?: string | null };
}

const Component: FunctionComponent = () => {
  const classes = useStyles();
  const [blogs, setBlogs] = React.useState<blogType>();
  const [comments, setComments] = React.useState<IComments[]>([]);
  const [userName, setUserName] = React.useState<string>("");
  const [comment, setComment] = React.useState<string>("");
  const [laoding, setLoading] = React.useState<boolean>(true);
  const [submitLoading, setSubmitLoading] = React.useState<boolean>(false);
  const [alert, setAlert] = React.useState<boolean>(false);
  const [erroralert,
    setErrorAlert] = React.useState<boolean>(false);
  const { id }: any = useParams();

  const [error, setError] = React.useState<IErrorType>({
    userName: { status: true },

    comment: { status: true },
  });

  React.useEffect(() => {
    fetchBlogData();
  }, []);

  const fetchBlogData = () => {
    getBlogsDetail(id)
      .then((response: any) => {
        setLoading(false);
        if (response) {
          setBlogs(response);
          setComments(response.comments);
        }
      })
      .catch((er) => {
        setLoading(false);
      });
  };


  const onChangeInputs = (
    event: React.ChangeEvent<{ name?: string | undefined; value: unknown }>,
    handler: React.Dispatch<string>,
    name: string
  ) => {
    handler(String(event.target.value));
    if (error[name].status === false) {
      setError((prevError) => {
        return {
          ...prevError,
          [name]: { status: true },
        };
      });
    }
  };

  const checkValidation = () => {
    let errorTmp: IErrorType = { ...error };
    let valid = true;
    if (!userName) {
      errorTmp["userName"] = { status: false };
      valid = false;
    }

    if (!comment) {
      errorTmp["comment"] = { status: false };
      valid = false;
    }

    setError(errorTmp);
    return valid;
  };

  const onSubmit = () => {
    if (alert) {
      setAlert(false);
    }
    if (checkValidation() && blogs) {
      setSubmitLoading(true);
      const data = {
        name: userName,
        content: comment,
        senderEmail: "sdfjs@gmail.com",
      };

      AddComment(data, blogs.id)
        .then((response: any) => {
            setSubmitLoading(false);
            setComment("");
            setUserName("");
            setAlert(true);
            setTimeout(() => {
              setAlert(false);
            }, 4000);

        })
        .catch((er) => {
          setSubmitLoading(false);
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 4000);
        });
    }
  };

  return (
    <>
      <Container className={classes.root}>
        <Grid container justify={"center"}>
          <Grid item md={9} xs={11}>
            <Grid item md={12} className={classes.sitemap}>
              {formatMessage("blog_detail.sitemap.home") +
                " / " +
                formatMessage("blog_detail.sitemap.blog") +
                " / " +
                blogs?.title}
            </Grid>
          </Grid>
        </Grid>
      </Container>
      {laoding ? (
        <div className={classes.loading}>
          <img src="/images/loading.svg" alt="loading" />
        </div>
      ) : (
        <>
          <Grid className={classes.container}>
            {blogs && (
              <Container className={classes.root}>
                <Grid container justify={"center"}>
                  <Grid item md={9} xs={11}>
                    <Grid item md={12} xs={12}>
                      <Grid container justify={"center"}>
                        <img
                          src={blogs?.coverImage.toString()}
                          alt="coverImage"
                        />
                      </Grid>
                      <div
                        className={classes.content}
                        dangerouslySetInnerHTML={{ __html: blogs.content }}
                      ></div>
                    </Grid>
                    <Typography className={classes.subTitle}>
                      {formatMessage("blog_detail.tags")}
                    </Typography>
                    <Grid container className={classes.tagContainer}>
                      {blogs.tags.map((item) => {
                        return (
                          <Grid key={item} className={classes.tag}>
                            {item}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Grid>
          <div className={classes.commentsRoot}>
            <Container>
              <Grid container justify={"center"}>
                <Grid item md={9} xs={11}>
                  {comments && comments.length > 0 && (
                    <>
                      <Typography className={classes.subTitle}>
                        {formatMessage("blog_detail.comments")}
                      </Typography>
                      <Grid container className={classes.commentContainer}>
                        {comments.map((item) => {
                          return (
                            <Grid
                              key={item._id}
                              className={classes.comment}
                              container
                              alignItems="center"
                            >
                              <Grid item md={1}>
                                <img src="/images/person.svg" alt="icon" />
                              </Grid>
                              <Grid
                                item
                                md={11}
                                className={classes.commentText}
                              >
                                <Typography component="span">
                                  {item.name} -
                                  {moment(new Date(item.createdAt)).format(
                                    "D MMM YYYY"
                                  )}
                                </Typography>
                                <Typography>{item.content}</Typography>
                                {item.reply && (
                                  <>
                                    <Typography className={classes.replyer}>
                                      {formatMessage(
                                        "blog_detail.comment.replyer"
                                      )}
                                    </Typography>
                                    <Typography>{item.reply.text}</Typography>
                                  </>
                                )}
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}

                  <Typography className={classes.subTitle2}>
                    {formatMessage("blog_detail.leave_comment.title")}
                  </Typography>
                  <Grid container>
                    <Grid item md={12} xs={12} className={classes.filedBox}>
                      <TextField
                        error={!error.comment.status}
                        value={comment}
                        type="message"
                        fullWidth
                        onChange={(
                          e: React.ChangeEvent<{
                            name?: string | undefined;
                            value: unknown;
                          }>
                        ) => onChangeInputs(e, setComment, "comment")}
                        id="comment-input"
                        name="comment"
                        rows={5}
                        rowsMax={5}
                        multiline={true}
                        placeholder={formatMessage(
                          "blog_detail.leave_comment.form.text"
                        )}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} className={classes.filedBox}>
                      <TextField
                        error={!error.userName.status}
                        value={userName}
                        type="name"
                        fullWidth
                        onChange={(e) =>
                          onChangeInputs(e, setUserName, "userName")
                        }
                        id="name-input"
                        name="userName"
                        placeholder={formatMessage(
                          "blog_detail.leave_comment.form.text.name"
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      md={12}
                      xs={12}
                      className={classes.filedBoxButton}
                    >
                      <Button
                        color="primary"
                        variant="contained"
                        startIcon={<img src="/images/send.svg" alt="" />}
                        border
                        hasLoader
                        loading={submitLoading}
                        onClick={onSubmit}
                      >
                        <span className={classes.btnText}>
                          {formatMessage(
                            "blog_detail.leave_comment.form.submit"
                          )}
                        </span>
                      </Button>
                      {alert && (
                        <div className={classes.alert}>
                          {formatMessage(
                            "blog_detail.leave_comment.form.success"
                          )}
                        </div>
                      )}
                      {erroralert && (
                          <div className={classes.erroralert}>
                            {formatMessage(
                                "blog_detail.leave_comment.form.error"
                            )}
                          </div>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </div>
        </>
      )}
    </>
  );
};

export default Component;
