import { Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import useStyles from "./style";
import { formatMessage } from "../../utils/local";
import moment from "moment-mini";

export interface blogType {
  coverImage: {
    downloadUrl: string;
  };
  id: string;
  summary: string;
  title: string;
  content: string;
  createdAt: string;
  tags: string[];
  author?: string;
  slug: string;
}

interface IProp {
  data: blogType;
}

const Component: FunctionComponent<IProp> = ({ data }) => {
  const classes = useStyles();
  return (
    <Link to={`/blog/${data.id}`}>
      <div className={classes.mainRoot}>
        <div className={classes.pic}>
          <img src={data.coverImage.toString()} alt="" />
        </div>

        <Grid container alignItems={"center"}>
          <Grid item md={12} xs={12}>
            <Typography className={classes.name}>{data.title}</Typography>
            <Typography className={classes.role}>{data.summary}</Typography>
            <Grid container justify="space-between">
              <Grid className={classes.footerText}>
                <Typography component="span">
                  {moment(new Date(data.createdAt)).format("D MMM YYYY")}
                </Typography>
                {data.author && (
                  <Typography component="span">
                    - {formatMessage("home.blogs.author")} : {data.author}
                  </Typography>
                )}
              </Grid>
              <Grid>
                <Grid container>
                  {data.tags.length > 0 &&
                    data.tags.map((item) => {
                      return (
                        <Grid key={item} className={classes.tag}>
                          {item}
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Link>
  );
};

export default Component;
