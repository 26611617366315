import React, { FunctionComponent } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useStyles from "./style";
import { useTheme } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
export interface ISlide {
        image: string,
        alt: string
}

interface IProp {
    slides: ISlide[];
}

const Slide: FunctionComponent<IProp> = ({ slides}) => {
    const classes = useStyles();
    const theme = useTheme();

    var settings = {
        dots: useMediaQuery(theme.breakpoints.down("xs")) ? false : true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 10000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // rtl: true,
    };
    return (
        <div className={classes.root}>
            <Slider {...settings}>
            {slides &&
            slides.map((item, index) => {
                return (
                    <div key={index}>
                    <img src={item.image} alt={item.alt} />
                </div>
            );
            })}
    </Slider>
    </div>
);
};

export default Slide;