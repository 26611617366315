import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(5, 1),
  },
  container: {
    padding: theme.spacing(2, 0),
  },
  title: {
    fontSize: 50,
    fontFamily: "poppins-bold",
    paddingBottom: theme.spacing(3),
    color: "#2a3a50",
  },
  on_progress: {
    textAlign: "center",
    fontSize: 30,
    fontFamily: "poppins-bold",
    marginTop: 30,
    paddingBottom: theme.spacing(3),
    color: "#2a3a50",
  },
  desc: {
    padding: theme.spacing(1, 0),
    color: "#2a3a50",
  },
  sertifTitle: {
    fontWeight: "bold",
    padding: theme.spacing(1, 0),
  },
  pic: {
    padding: 8,
    background: "rgba(84, 108, 136, 0.1)",
    borderRadius: 5,
    margin: "8px 0",
    "& img": {
      width: "100%",
    },
  },
  divider: {
    width: "100%",
    margin: theme.spacing(5, 0, 4, 0),
  },
  item: {
    "&:last-child": {
      "&>h4": {
        display: "none",
      },
    },
  },
}));
