import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3),
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
      padding: theme.spacing(3, 2),
    },

    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    "& p": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 15,
      },
    },
  },
  commentsRoot: {
    padding: theme.spacing(3, 3),
    background: "rgba(42, 58, 80, 0.03)",
    [theme.breakpoints.down("xs")]: {
      fontSize: 23,
      padding: theme.spacing(3, 2),
    },
  },

  title: {
    fontSize: 45,
    fontFamily: "poppins-bold",
  },
  container: {
    padding: theme.spacing(3, 0),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    padding: "150px 0",
    "& img": {
      width: 100,
    },
  },
  sitemap: {
    background: "rgba(42, 58, 80, 0.05)",
    color: "rgba(42, 58, 80, 0.5)",
    padding: theme.spacing(2, 2.5),
    borderRadius: 10,
    fontSize: 14,
  },
  tag: {
    border: "solid 1px rgba(42, 58, 80, 0.3)",
    color: "rgba(42, 58, 80, 0.8)",
    padding: theme.spacing(0, 0.5),
    fontSize: 13,
    borderRadius: 5,
    margin: theme.spacing(0, 0.5),
  },
  tagContainer: {
    padding: theme.spacing(2, 0),
  },
  subTitle: {
    fontSize: 24,
    fontWeight: 600,
  },
  subTitle2: {
    fontSize: 24,
    fontWeight: 600,
    paddingTop: theme.spacing(2),
  },
  commentContainer: {
    padding: theme.spacing(2, 3),
    margin: theme.spacing(2, 0),
    borderRadius: 10,
    background: "white",
  },
  comment: {
    padding: theme.spacing(2, 0),
    borderBottom: "solid 1px rgba(42, 58, 80, 0.1)",
    "&:last-child": {
      border: "none",
    },
  },
  commentText: {
    "&>span": {
      color: "rgba(42, 58, 80, 0.51)",
      fontSize: 12,
    },
    "&>p": {
      color: "rgba(42, 58, 80, 0.9)",
      fontSize: 14,
    },
  },
  replyer: {
    color: "#e77575 !important",
    padding: theme.spacing(2, 0, 0.5, 0),
    fontSize: 12,
  },
  filedBox: {
    padding: theme.spacing(1, 0),
    "& label": {
      padding: theme.spacing(1, 0, 2, 0),
      fontWeight: 600,
    },
  },
  filedBoxButton: {
    padding: theme.spacing(1, 0),
    display: "flex",
    alignItems: "center",
  },
  btnText: {
    padding: theme.spacing(0, 1),
  },
  alert: {
    background: "rgb(237, 247, 237)",
    color: "rgb(30, 70, 32)",
    padding: theme.spacing(1, 2),
    fontSize: 14,
    borderRadius: 4,
    marginLeft: 16,
  },

  erroralert: {
    background: "#FFEBEE",
    color: "#B71C1C",
    padding: theme.spacing(1, 2),
    fontSize: 14,
    borderRadius: 4,
    marginLeft: 16,
  },
  content: {
    "&>div div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
}));
