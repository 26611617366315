import React, { FunctionComponent } from "react";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { formatMessage } from "../../utils/local";
import useStyles from "./style";
import LanguageSelect from "../../layout/header/languageSelect";
import { Collapse } from "@material-ui/core";
import { IType } from "../../App";

const isRtl =
  sessionStorage.getItem("rtl") !== undefined
    ? sessionStorage.getItem("rtl") === "true"
    : process.env.REACT_APP_RTL;

type Anchor = "top" | "left" | "bottom" | "right";
const anchor = isRtl ? "left" : "right";

const menuItems = [
  {
    name: formatMessage("menuItem.home"),
    link: "/",
  },
  {
    name: formatMessage("menuItem.products"),
    link: "/products",
    children: [],
  },
  {
    name: formatMessage("menuItem.certificates"),
    link: "/certificates",
  },
  {
    name: formatMessage("menuItem.about"),
    link: "/about",
    innerLink: true,
  },
  {
    name: formatMessage("menuItem.contact"),
    link: "/#contact",
    innerLink: true,
  },
  {
    name: formatMessage("menuItem.blog"),
    link: "/blogs",
  },
];

interface IProp {
  types: IType[];
}

const TemporaryDrawer: FunctionComponent<IProp> = ({ types }) => {
  const classes = useStyles();
  const location = useLocation();

  const [isExpand, setIsExpand] = React.useState<boolean[]>([]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const toggleMenu = (index: number) => {
    const newArray: boolean[] = [...isExpand];
    newArray[index] = !newArray[index];
    setIsExpand(newArray);
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <List>
        {menuItems.map((item, index) => (
          <div key={item.name}>
            {item.children && (
              <div
                onClick={(e) => {
                  toggleMenu(index);
                }}
              >
                <ListItem
                  button
                  key={item.name}
                  className={isRtl ? classes.item : classes.item2}
                  // onClick={toggleMenu(index)}
                  // onKeyDown={toggleDrawer(anchor, false)}
                >
                  <>
                    <ListItemText primary={item.name} />
                    <span
                      className={
                        location.pathname === item.link ? classes.line : ""
                      }
                    ></span>
                    {item.children && isExpand[index] ? (
                      <img src="/images/arrow-down.svg" alt="down-arrow" />
                    ) : (
                      <img src="/images/arrow-down.svg" alt="down-arrow" />
                    )}
                  </>
                </ListItem>

                <Collapse in={isExpand[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {types.map((child) => {
                      return (
                        <Link to={`${item.link}/${child.id}`}>
                          <ListItem
                            onClick={toggleDrawer(anchor, false)}
                            className={classes.nestedChild}
                            button
                          >
                            <ListItemText primary={child.title} />
                          </ListItem>
                        </Link>
                      );
                    })}
                  </List>
                </Collapse>
              </div>
            )}
            {!item.children && (
              <>
                <ListItem
                  button
                  key={item.name}
                  className={isRtl ? classes.item : classes.item2}
                  onClick={toggleDrawer(anchor, false)}
                  onKeyDown={toggleDrawer(anchor, false)}
                >
                  {item.innerLink ? (
                    <a href={item.link}>
                      <ListItemText primary={item.name} />
                      <span
                        className={
                          location.pathname === item.link ? classes.line : ""
                        }
                      ></span>
                    </a>
                  ) : (
                    <Link to={item.link}>
                      <ListItemText primary={item.name} />
                      <span
                        className={
                          location.pathname === item.link ? classes.line : ""
                        }
                      ></span>
                    </Link>
                  )}
                </ListItem>

                <Collapse in={isExpand[index]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItem button>
                      <ListItemText primary="Starred" />
                    </ListItem>
                  </List>
                </Collapse>
              </>
            )}
          </div>
        ))}
      </List>
      <Divider />
      <div className={classes.lang}>
        <LanguageSelect />
      </div>
    </div>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(anchor, true)}>
        <img src="/images/menu.svg" alt="menu-icon" />
      </Button>
      <Drawer
        anchor={anchor}
        open={state[anchor]}
        onClose={toggleDrawer(anchor, false)}
      >
        {list(anchor)}
      </Drawer>
    </div>
  );
};

export default TemporaryDrawer;
