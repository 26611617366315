import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) =>
  createStyles({
    buttonWrapper: {
      position: "relative",
      display: "flex",
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    myBtn: {
      padding: 8,
      background: "rgba(229, 120, 120, 0.1)",
      width: "fit-content",
      borderRadius: 10,
    },
    myBtn2: {
      padding: 8,
      background: "rgba(42, 58, 80, 0.1)",
      width: "fit-content",
      borderRadius: 10,
    },
    rtl: {
      "& img": {
        transform: "rotate(180deg)",
      },
      "& svg": {
        transform: "rotate(180deg)",
        marginRight: 8,
      },
    },
  })
);
