import Lottie from "lottie-react";
import Water_Crisis from "./chicken.json";

const style = {
  height: 300,
  
};

const Chicken = () => {
  return (
    <Lottie
      animationData={Water_Crisis}
      style={style}
      loop={true}
      autoplay={true}
    />
  );
};

export default Chicken;