import { Hidden } from "@material-ui/core";
import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import useStyles from "./style";

const Logo: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Link to="/">
        <Hidden smDown>
          <img src="/images/Logo.png" alt="logo" />
        </Hidden>
        <Hidden mdUp>
          <img src="/images/Logo.png" alt="logo" />
        </Hidden>
      </Link>
    </div>
  );
};

export default Logo;
